/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@ionic/angular/css/display.css';

.full-width {
  width: 100%;
  min-width: 100%;
}

.popover-autocomplete {
  --width: 100%;
  --height: 100%;

  .popover-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    width: 100%;
    height: 100%;
  }

  .popover-content {
    overflow-y: hidden !important;
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    top: 0px !important;
    left: 0px !important;
  }
}

.checkbox-wide {
  min-width: 210px !important;
}

.info-alert {
  button {
    color: #ffffff !important;
    background-color: var(--ion-color-tertiary) !important;
    min-width: 64px !important;
    max-width: 64px !important;

    span {
      justify-content: center !important;
    }
  }

  h2 {
    text-align: center;
  }

  .alert-button-group {
    justify-content: center !important;
  }

  .alert-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;

    ion-icon {
      color: var(--ion-color-tertiary);
      font-size: 40px;
    }
  }
}

.modal-80 {
  --width: 80%;
  --height: 80%;
}

.modal-90 {
  --width: 90%;
  --height: 90%;
}

.modal-100 {
  --width: 100%;
  --height: 100%;
}

.modal-small {
  --width: 250px;
  --height: 260px;
}

ion-card-title {
  color: #737373 !important;
}

.white-space-inherit {
  white-space: inherit !important;
}

.action-toast {
  .toast-wrapper {
    .toast-container {
      background-color: var(--ion-color-primary) !important;
      color: #ffffff;
    }
  }
}

.action-toast {
  --background: var(--ion-color-primary);
  --color: #ffffff;
  --button-color: #ffffff;
}

.action-toast-button {
  --background: transparent;
}

.incomplete-data {
  font-size: 16px;
  color: red;
}

.inline-date-picker-item {
  ion-datetime-button {
    width: 100% !important;
    height: 100% !important;
  }

  ion-datetime-button::part(native) {
    height: 95% !important;
    width: 100% !important;
    padding-left: 8px;
    background: transparent !important;
    text-align: start !important;
  }
}

.ios {
  ion-item {
    --highlight-height: 2px;
  }
}

ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}

.sc-ion-modal-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

// New style added with upgrade
.required .label-text::after {
  content: ' *';
  color: red;
}

[hidden] {
  display: none !important;
}
