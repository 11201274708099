// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $app-primary: mat.define-palette(mat.$indigo-palette);
// $app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $app-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $app-theme: mat.define-light-theme((
//   color: (
//     primary: $app-primary,
//     accent: $app-accent,
//     warn: $app-warn,
//   )
// ));

// PUBLIC

$app-primary: mat.m2-define-palette(mat.$m2-blue-grey-palette, 500);
$app-accent: mat.m2-define-palette(mat.$m2-cyan-palette, 500);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$app-text: mat.m2-define-palette(mat.$m2-grey-palette, 600);
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warm: $app-warn
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);
$text-input-ios-show-focus-highlight: true;

// IONIC

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

:root {
  --ion-color-primary: #607d8b;
  --ion-color-primary-rgb: 96, 125, 139;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #546e7a;
  --ion-color-primary-tint: #708a97;

  --ion-color-secondary: #90a4ae;
  --ion-color-secondary-rgb: 144, 164, 174;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #7f9099;
  --ion-color-secondary-tint: #9badb6;

  --ion-color-tertiary: #4dd0e1;
  --ion-color-tertiary-rgb: 77, 208, 225;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #44b7c6;
  --ion-color-tertiary-tint: #5fd5e4;

  --ion-color-success: #4dd0e1;
  --ion-color-success-rgb: 77, 208, 225;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #44b7c6;
  --ion-color-success-tint: #5fd5e4;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #e57373;
  --ion-color-danger-rgb: 229, 115, 115;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #ca6565;
  --ion-color-danger-tint: #e88181;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-whatsapp: #56c864;
  --ion-color-whatsapp-rgb: 86, 200, 100;
  --ion-color-whatsapp-contrast: #000000;
  --ion-color-whatsapp-contrast-rgb: 0, 0, 0;
  --ion-color-whatsapp-shade: #4cb058;
  --ion-color-whatsapp-tint: #67ce74;
}

.ion-color-whatsapp {
  --ion-color-base: var(--ion-color-whatsapp);
  --ion-color-base-rgb: var(--ion-color-whatsapp-rgb);
  --ion-color-contrast: var(--ion-color-whatsapp-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whatsapp-contrast-rgb);
  --ion-color-shade: var(--ion-color-whatsapp-shade);
  --ion-color-tint: var(--ion-color-whatsapp-tint);
}
